/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .quotes {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .quote-img {
        object-fit: cover;
        height: 70vh;
        width: 100%;
    }

    .quote-block {}

    .quote-icon {
        font-size: 4em;
    }

    .quote-info {
        padding: 0.5em;
    }

    .quote-info h1 {
        font-size: 1.4em;
    }

    .quote-info p {
        font-size: 0.8em;
    }

    .quote-dd {
        -webkit-appearance: none;
        -moz-appearance: none;
        display: block;
        width: 100%;
        padding: 0.8em;
        font-size: 1em;
    }

    .quote-input-pair {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5em;
        margin-bottom: 0.8em;
    }

    .quote-input-pair input {
        -webkit-appearance: none;
        -moz-appearance: none;
        font-family: 'Roboto', sans-serif;
        padding: 0.8em;
        font-size: 1em;
    }

    .quote-input-pair textarea {
        -webkit-appearance: none;
        -moz-appearance: none;
        font-family: 'Roboto', sans-serif;
        min-height: 150px;
        padding: 0.8em;
        font-size: 1em;
    }

    .quote-btn {
        display: block;
        width: 100%;
        padding: 0.8em;
        font-size: 1em;
    }

    .quote-content {
        padding: 1em;
        border-radius: 8px;
    }
    .quote-img {
        display: none;
    }
}

@media only screen and (min-width: 600px) {
    .quote-wrap {
        width: 80%;
        margin: 0 auto;
    }
    
    .quote-icon {
        font-size: 3em;
    }
}

@media only screen and (min-width: 800px) {
    .quote-wrap {
        width: 65%;
    }
}

@media only screen and (min-width: 1000px) {
    .quote-img {
        display: block;
        height: 90vh;
    }
    .quotes {
        grid-template-columns: 1fr 1fr;
        width: 85%;
        margin: 1em auto;
    }
    .quote-wrap {
        width: 90%;
    }
}

@media only screen and (min-width: 1200px) {
    .quotes {
        width: 70%;
    }
}