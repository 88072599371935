@media only screen and (min-width: 350px) {
    .products {
        padding: 1em;
    }

    .products-right {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .products-left {
        padding: 1em 0;
    }

    .product-block {
        padding: 1em;
    }

    .product-img {
        width: 100%;
        max-height: 300px;
    }

    .product-name {
        font-size: 1.4em;
        font-weight: 600;
        letter-spacing: -1px;
        text-transform: uppercase;
        padding: 0.5em 0;
    }
    .product-view {
        font-size: 0.9em;
        text-transform: uppercase;
        text-align: right;
        margin-top: 0.7em;
        text-decoration: none;
        letter-spacing: -1px;
    }
    .product-desc {
        font-size: 1.2em;
        letter-spacing: -1px;
        font-weight: 300;
    }
    .product-desc-name {
        font-size: 1.8em;
        font-weight: 500;
        letter-spacing: -1px;
        padding: 0.5em 0;
    }
    .product-price {
        font-size: 1.5em;
        letter-spacing: -1px;
    }
    .products-text {
        font-size: 1.4em;
        letter-spacing: -1px;
        font-weight: 300;
        padding: 1em 0;
    }
    .product-desc-wrap {
        padding: 0.8em;
    }
}

@media only screen and (min-width: 600px) {
    .products-right {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 800px) {
    .products-right {
        grid-template-columns: repeat(3, 1fr);
    }
    .product-img {
        max-height: 250px;
    }
}

@media only screen and (min-width: 1000px) {
    .products-wrap {
        display: grid;
        grid-template-columns: 30% 70%;
        gap: 1em;
        padding: 1em;
    }
}

@media only screen and (min-width: 1200px) {}