/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .shop {
        padding: 1em;
    }

    .shop-items {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .shop-item {
        padding: 1em;
    }

    .shop-item-price {
        font-size: 1.4em;
        font-weight: 600;
        letter-spacing: -1px;
    }

    .shop-item img {
        width: 100%;
        margin-bottom: 0.5em;
        border-radius: 10px;
        object-fit: cover;
        height: 300px;
        object-position: center;
    }

    .shop-item-name {
        font-size: 1.1em;
        font-weight: 400;
    }

    .shop-item-desc {
        font-size: 0.9em;
        font-weight: 400;
        padding: 0.5em 0;
    }

    .shop-item-img {
        position: relative;
    }

    .shop-item-btn {
        display: block;
        position: absolute;
        right: 0.8em;
        top: 0.8em;
        border-radius: 30px;
        width: 40px;
        height: 40px;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }

    .shop-item-btn-icon {
        font-size: 1.7em;
    }

    .shop-item-img-arrow-left {
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 2.2em;
        position: absolute;
        left: -10px;
        margin-top: 50%;
    }

    .shop-item-img-arrow-right {
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 2.2em;
        position: absolute;
        right: -10px;
        margin-top: 50%;
    }

    .shop-item-show {
        display: block;
        font-size: 0.8em;
        padding: 1em 0;
        text-align: center;
    }

    .shop-item-show:hover {
        cursor: pointer;
    }

    .shop-item-qty {
        font-size: 0.8em;
    }

    .shop-category-pair {
        display: flex;
        gap: 0.5em;
    }

    .shop-cart-icon {
        font-size: 35px;
    }

    .cart {
        margin-bottom: 1em;
    }

    .cart-prods {
        padding: 1em;
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.6em;
    }

    .cart h1 {
        font-size: 1.7em;
        padding: 1em 0.5em 0.5em 0em;
    }

    .cart-item-img {
        width: 55px;
        height: 55px;
    }

    .cart-item {
        display: grid;
        grid-template-columns: 1fr 4fr;
        height: fit-content;
        align-items: center;
        border-radius: 10px;
        padding: 0.4em;
        box-shadow: 6px 5px 5px rgba(0, 0, 0, 0.1);
    }

    .cart-item-name {
        font-size: 1em;
        font-weight: 600;
    }

    .cart-item-price {
        font-size: 0.8em;
        font-weight: 400;
    }

    .cart-item-qty-block {
        display: flex;
        gap: 0.5em;
        align-items: center;
    }

    .cart-item-qty-block button {
        font-size: 1.6em;
        padding: 0.2em 0em;
        border-radius: 20px;
    }

    .cart-item-qty-block p {
        font-size: 1.1em;
    }

    .cart-review {
        padding: 1em;
    }

    .cart-rev-pair p {
        font-size: 0.9em;
    }

    .cart-rev-pair h4 {
        font-size: 1.4em;
    }

    .pay-now-btn {
        width: 100%;
        padding: 0.6em;
        border-radius: 20px;
        margin-top: 1em;
        font-size: 1em;
        z-index: 0;
    }

    .cart-item-rem {
        font-size: 0.8em;
        padding: 0.5em;
        margin-left: 0.5em;
    }

    .cart-item-block {
        padding: 0.5em;
    }

    .no-cart-items {
        font-size: 1.2em;
        font-weight: 300;
        padding: 1em;
    }

    .customer-details {
        padding: 1em 0;
    }

    .customer-details label {
        font-size: 0.9em;
    }

    .customer-details p {
        font-size: 0.8em;
    }

    .customer-details-tb {
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 0.8em;
        width: 100%;
        margin: 0.8em 0;
    }
}

@media only screen and (min-width: 600px) {
    .shop-items {
        grid-template-columns: 1fr 1fr;
    }

    .cart-prods {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 800px) {
    .shop-wrap {
        display: grid;
        grid-template-columns: 20% 80%;
    }

    .cart-wrap {
        display: grid;
        grid-template-columns: 3fr 1fr;
    }
}

@media only screen and (min-width: 1000px) {
    .shop-wrap {
        grid-template-columns: 15% 85%;
    }

    .shop-items {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .shop-item img {
        height: 40vh;
    }
}

@media only screen and (min-width: 1200px) {
    .shop-wrap {
        grid-template-columns: 12% 88%;
    }

    .shop-items {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .cart-wrap {
        grid-template-columns: 5fr 1fr;
    }

    .cart-prods {
        grid-template-columns: repeat(3, 1fr);
    }
}