/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .schedule {
        padding: 1em;
    }

    .schedule-pick-date {
        padding: 0.8em;
        font-family: 'Roboto', sans-serif;
        font-size: 1em;
    }
    .schedule-pick {
        font-size: 1.2em;
        padding: 0.5em 0;
        letter-spacing: -1px;
    }
    .schedule-pick-dd {
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 0.8em;
        font-size: 1em;
    }
    .schedule-pick-btn {
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 1em;
        width: 200px;
        margin: 1em 0;
    }
    .schedule-right {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.4em;
        height: fit-content;
    }
    .schedule-slot button {
        -webkit-appearance: none;
        -moz-appearance: none;
        font-family: 'Roboto', sans-serif;
        font-size: 1.1em;
        padding: 1em; 
        width: 100%;
    }
    .schedule-rev {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: #111111c7;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
    .schedule-rev-wrap {
        margin: 1em;
        padding: 1em;
        max-width: 450px;
        margin: 1em auto;
    }
    .schedule-rev-icon {
        font-size: 2em;
    }
    .schedule-rev-wrap h1 {
        font-size: 1.5em;
    }
    .schedule-rev-wrap p {
        font-size: 0.9em;
        font-weight: 300;
        padding: 0.8em 0;
    }
    .schedule-rev-date {
        font-size: 1.6em;
        text-align: center;
        font-weight: 800;

    }
    .schedule-rev-details {
        text-align: center;
        font-weight: 300;
        font-size: 1.2em;
    }
    .schedule-rev-pair {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.2em;
    }
    .schedule-rev-pair label {
        font-size: 0.9em;
    }
    .schedule-rev-pair input {
        padding: 0.8em
    }
    .schedule-rev-btn {
        margin-top: 0.8em;
        padding: 0.8em;
        width: 100%;
        font-family: 'Roboto', sans-serif;
    }
    .type-desc {
        font-size: 1.1em;
        font-weight: 300;
        padding: 1em;
        margin: 1em 0;
    }
}

@media only screen and (min-width: 600px) {
    .schedule-rev-wrap {
        width: 75%;
        margin: 1em auto;
    }
}

@media only screen and (min-width: 800px) {
    .schedule-rev-wrap {
        width: 60%;
    }
    .schedule-wrap {
        display: grid;
        grid-template-columns: 1fr 4fr;
        gap: 1em;

    }
    .schedule-slot button {
        font-size: 1em;
        padding: 1em; 
    }
}

@media only screen and (min-width: 1000px) {
    .schedule-rev-wrap {
        width: 45%;
    }
    .schedule-right {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (min-width: 1200px) {
    .schedule-rev-wrap {
        width: 30%;
    }
    .schedule-right {
        grid-template-columns: repeat(5, 1fr);
    }
}