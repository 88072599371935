/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .schedule-admin {
        padding: 1em;
    }

    .schedule-admin-date {
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 1em 2em;
        font-family: 'Roboto', sans-serif;
        font-size: 1em;
    }

    .schedule-admin-btn {
        padding: 1em;
        font-family: 'Roboto', sans-serif;
        font-size: 1em;
        border-radius: 10px;
    }

    .schedule-admin-eve {
        padding: 1em;
        height: fit-content;
    }

    .schedule-admin-eve h4 {
        font-size: 1.5em;
    }

    .schedule-admin-wrap {
        display: grid;
        grid-template-columns: 1fr;

    }

    .schedule-admin-times {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.4em;
        height: fit-content;
    }

    .schedule-clock {
        text-align: center;
        font-size: 2.4em;
    }

    .clock-wrap {
        display: flex;
        gap: 0.5em;
        align-items: center;
        border-bottom: 2px solid black;
        width: fit-content;
    }

    .clock-icon {
        font-size: 2em;
    }

    .schedule-admin-create {
        margin-top: 2vh;
        font-size: 1em;
        border-radius: 10px;
        padding: 1em;
        width: 100%;
    }

    .schedule-admin-create:hover {
        cursor: pointer;
    }

    .schedule-admin-form {
        position: fixed;
        background-color: #111111b5;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 100;
        overflow-y: scroll;
    }

    .schedule-admin-form-wrap {
        padding: 1em;
        max-width: 450px;
        margin: 2em auto
    }

    .schedule-admin-form-wrap h1 {
        font-size: 1.5em;
        font-weight: 400;
        line-height: 25px;
    }

    .schedule-admin-form-pair {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.2em;
    }

    .schedule-admin-form-pair label {
        font-size: 0.9em;
    }

    .schedule-admin-form-pair input {
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 0.8em;
        margin-bottom: 0.6em;
        font-family: 'Roboto', sans-serif;
        font-size: 1em;
        width: 100%;
    }

    .schedule-admin-form-pair textarea {
        padding: 0.8em;
        margin-bottom: 0.6em;
        font-family: 'Roboto', sans-serif;
        font-size: 1em;
        min-height: 120px;
    }

    .split {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
    }

    .big-split {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 1em;
    }

    .schedule-admin-form-btn {
        width: 100%;
        padding: 1em;
        font-family: 'Roboto', sans-serif;
        font-size: 1em;
    }

    .schedule-xmark {
        font-size: 5em;
    }

    .schedule-admin-btns {
        width: 320px;
    }

    .appt-types-wrap {
        position: fixed;
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #ffffff;
        z-index: 1000;
        padding: 1em;

    }

    .appt-types {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5em;
        padding: 1em 0;
    }

    .appt-types button {
        width: 100%;
        padding: 0.8em;
        font-size: 1em;
    }

    .appt-types-top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.2em 0;
    }

    .appt-types-top h1 {
        font-size: 1.5em;
        letter-spacing: -1px;
        font-weight: 500;
    }

    .appt-types-wrap p {
        font-size: 0.9em;
    }

    .appt-types-x {
        font-size: 2em;
    }
}

@media only screen and (min-width: 600px) {
    .schedule-admin-form-wrap {
        width: 450px;
    }

    .appt-types-wrap {
        display: block;
        left: 0;
        right: 0;
        width: 450px;
        margin: 2em auto;
        height: 85vh;
        overflow-y: scroll;
    }
}

@media only screen and (min-width: 800px) {
    .schedule-admin {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .schedule-clock {
        text-align: left;
    }
}

@media only screen and (min-width: 1000px) {
    .schedule-admin {
        width: 90vw;
        margin: 0 auto;
    }

    .schedule-admin-wrap {
        max-height: 60vh;
        overflow-y: scroll;
        min-height: 60vh;
    }

    .schedule-admin-times {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 1200px) {}