/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .portfolio-wrap {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .portfolio-sub {
        font-size: 1.2em;
        font-weight: 300;
        padding: 1em 0;
    }

    .portfolio-block {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;
    }

    .portfolio-left {
        padding: 0;
        margin: 0;
        height: 40vh;
        object-fit: cover;
        width: 100%;
    }

    .portfolio-right {
        padding: 1.5em;
    }

    .portfolio-right h1 {
        font-size: 1.5em;
    }

    .portfolio-right p {
        display: block;
        font-size: 1em;
        padding: 1em 0;
        font-weight: 300;
    }

    .portfolio-link {
        display: flex;
        align-items: center;
        gap: 0.4em;
        text-align: left;
        padding: 0.6em;
        border-radius: 20px;
        font-size: 1.2em;
        text-decoration: none;
    }
}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 800px) {
    .portfolio-sub {
        width: 80%;
        margin: 1em auto;
    }

    .portfolio-block {
        display: grid;
        grid-template-columns: 3fr 2fr;
    }
    .portfolio-left {
        height: 100%;
    }
}

@media only screen and (min-width: 1000px) {
    .portfolio-sub {
        width: 70%;
    }

    .portfolio-block {
        grid-template-columns: 5fr 3fr;
    }
}

@media only screen and (min-width: 1200px) {
    .portfolio-sub {
        width: 60%;
    }
    .portfolio-left {
        height: 55vh;
    }
}