@media only screen and (min-width: 350px) {
    .pricing {
        padding: 1em;
    }
    .pricings {
        margin-top: 1em;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .pricing-block {
        padding: 1.5em;
    }

    .pricing-block-list {
        list-style-type: none;
        padding: 1em 0;
    }

    .pricing-block-list li {
        display: flex;
        align-items: center;
        padding: 0.2em 0;
        gap: 0.3em;
    }

    .pricing-block-list li p {
        font-size: 0.8em;
        font-weight: 300;
    }
    .pricing-name {
        font-size: 1.3em;
        font-weight: 600;
        letter-spacing: -1px;
    }
    .pricing-desc {
        font-size: 1em;
        color: gray;
    }
    .pricing-price {
        font-size: 2.5em;
        font-weight: 900;
        letter-spacing: -2px;
        padding: 0.2em 0;
    }
    .pricing-info {
        font-size: 1.2em;
        font-weight: 300;
        padding: 1em;
    }
}

@media only screen and (min-width: 600px) {
    .pricings {
        grid-template-columns: 1fr 1fr;
    }
    .pricing-info {
        width: 80vw;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 800px) {
    .pricings {
        width: 80vw;
        margin: 1em auto 0 auto;
    }
}

@media only screen and (min-width: 1000px) {
    .pricings {
        width: 90%;
        grid-template-columns: repeat(3, 1fr);
    }
    .pricing-info {
        width: 75vw;
    }
}

@media only screen and (min-width: 1200px) {
    .pricings {
        width: 75%;
        grid-template-columns: repeat(3, 1fr);
    }
    .pricing-info {
        padding: 2em;
        width: 60vw;
    }
}