/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .careers {
        padding: 1em;
    }

    .careers-left {
        padding: 2em;
    }

    .careers-head {
        font-size: 1.2em;
        color: gray;
        font-weight: 400;
    }

    .careers-types {
        padding: 1em;
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.6em;
        font-size: 1.1em;
    }

    .careers-type {
        font-size: 1.4em;
        text-decoration: underline;
    }

    .careers-right {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em
    }

    .career-block {
        padding: 1em;
    }

    .career-block h1 {
        font-size: 1.6em;
        line-height: 30px;
        letter-spacing: -1px;
    }

    .career-block p {
        font-weight: 200;
        font-size: 0.9em;
        padding: 0.5em 0;
    }

    .career-block h4 {
        font-size: 1.3em;
    }

    .career-block h5 {
        font-size: 0.8em;
        font-weight: 300;
        padding: 0.5em 0.8em;
        border-radius: 20px;
    }
}

@media only screen and (min-width: 600px) {
    .careers-wrap {
        display: grid;
        grid-template-columns: 40% 60%;
    }

    .careers-head {
        font-size: 1em;
    }

    .careers-type {
        font-size: 1em;
    }

    .careers-types {
        padding: 1em;
    }
}

@media only screen and (min-width: 800px) {}

@media only screen and (min-width: 1000px) {
    .careers-wrap {
        grid-template-columns: 35% 65%;
    }

    .careers-right {
        padding: 2em
    }
}

@media only screen and (min-width: 1200px) {}