/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .coupons {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .coupon {}

    .coupon-text {
        padding: 1em;
    }

    .coupon-exp {
        font-size: 1.2em;
        letter-spacing: -1px;
    }

    .coupon-amt {
        font-size: 2.5em;
        text-align: center;
        letter-spacing: -1px;
        padding: 0.5em;
    }

    .coupon-desc {
        font-size: 0.9em;
    }
}

@media only screen and (min-width: 600px) {
    .coupons {
        width: 95%;
        margin: 0 auto;
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 800px) {
    .coupons {
        width: 90%;
    }
}

@media only screen and (min-width: 1000px) {
    .coupons {
        width: 70%;
    }
}

@media only screen and (min-width: 1200px) {
    .coupons {
        grid-template-columns: repeat(3, 1fr);
    }
}