/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .awards-wrap {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .award {
        padding: 1em;
    }

    .award-icon {
        font-size: 2.6em;
    }

    .award-year {
        font-size: 1.8em;
        letter-spacing: -1px;
    }

    .award-vert-split {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5em 0;
        min-height: 200px;
    }

    .award-title {
        font-size: 1.6em;
        font-weight: 600;
        letter-spacing: -1px;
    }

    .award-desc {
        font-size: 0.9em;
        font-weight: 100;
    }

    .award-region {
        font-size: 1.2em;
    }
}

@media only screen and (min-width: 600px) {
    .awards-wrap {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media only screen and (min-width: 800px) {
    .awards-wrap {
        width: 85%;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1000px) {
    .awards-wrap {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (min-width: 1200px) {
    .awards-wrap {
        grid-template-columns: repeat(4, 1fr);
    }
}