/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .employees {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5em;
    }

    .emp-pair {
        padding: 1em;
    }

    .emp-pair h1 {
        font-size: 1.1em;
        font-weight: 400;
    }

    .emp-icon {
        font-size: 1.5em;
    }

    .emp-date-wrap {
        padding: 1em;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .emp-date-pair {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5em;
    }

    .emp-btn {
        -webkit-appearance: none;
        -moz-appearance: none;
        display: block;
        padding: 1em 3em;
        font-size: 1em;
        margin: 1em;
    }

    .punch-table {
        width: 100%;
        padding: 1em;
    }
    .punch-td {
        text-align: left;
        font-size: 1.2em;
    }
    .punch-th {
        font-size: 1.4em;
        text-align: left;
    }
    .punch-total {
        font-size: 1.8em;
        padding: 1em;
    }
    .punch-tr:nth-child(even) {
        background-color: #dee2f477;
    }
}

@media only screen and (min-width: 600px) {
    .emp-date-wrap {
        width: 300px;
    }
    .employees {
        width: 90%;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 800px) {
    .employees {
        width: 80%;
    }
}

@media only screen and (min-width: 1000px) {
    .employees {
        width: 70%;
    }
}

@media only screen and (min-width: 1200px) {
    .employees {
        width: 60%;
    }
}