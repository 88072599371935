@media only screen and (min-width: 350px) {
    .about {
        padding: 1em;
    }

    .about-wrap {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .about-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5em;
        margin-top: 1em;
    }

    .about-top {
        position: relative;
    }

    .about-img {
        height: 35vh;
        object-fit: cover;
        width: 100%;
        margin-top: 1.4em;
    }

    .about-sub {
        position: absolute;
        top: 0;
        font-size: 2.2em;
        letter-spacing: -1px;
        font-weight: 800;
        line-height: 1em;
    }

    .about-section p {
        font-size: 1em;
        font-weight: 300;
        padding: 0.4em 0;
    }
}

@media only screen and (min-width: 600px) {
    .about-section {
        gap: 1em;
    }

    .about-sub {
        font-size: 2.6em;
    }

    .about-text {
        padding: 1em;
    }

    .about-img {
        height: 40vh;
    }
}

@media only screen and (min-width: 800px) {
    .about-text {
        column-count: 2;
    }

    .about-section p {
        font-size: 1em;
    }

    .about-img {
        height: 40vh;
        margin-top: 2em;
    }

}

@media only screen and (min-width: 1000px) {

    .about-sub {
       font-size: 3em;
    }
    .about-img {
        height: 60vh;
        margin-top: 3em;
    }
}

@media only screen and (min-width: 1200px) {
    .about-section {
        width: 80vw;
        margin: 0 auto;
    }

    .about-sub {
        font-size: 4em;
        letter-spacing: -2px;
    }

    .about-img {
        height: 65vh;
    }

    .about-section p {
        font-size: 1.2em;
        padding: 0.6em 0;
    }
    .about-text {
        column-count: 3;
    }
}