/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .bio-option {
        padding: 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.4em;
    }
    .bio-option h1 {
        font-size: 1.2em;
        letter-spacing: -1px;
    }
    .bio-option p {
        font-size: 0.8em;
        color: gray;
    }
    .bio-option-arrow {
        font-size: 1.8em;
    }
    .bio-textblock {
        padding: 1em;
    }
    .bio-textblock h2 {
        font-size: 1.5em;
        letter-spacing: -1px;
        padding: 0.5em 0;
    }
    .bio-textblock p {
        font-size: 1em;
        font-weight: 300;
    }
    .bio-textblock img {
        width: 100%;
        padding: 1em 0;
    }
}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 800px) {
    .bio-split {
        display: grid;
        grid-template-columns: 40% 60%;
    }
    .bio-left-wrap {
        position: fixed;
        width: 40%;
    }
}

@media only screen and (min-width: 1000px) {
    .bio-split {
        grid-template-columns: 35% 65%;
    }
    .bio-left-wrap {
        width: 35%;
    }
    .bio-textblock {
        width: 90%;
        margin: 0 auto;
    }
    .bio-textblock p {
        font-size: 1.1em;
    }
    .bio-textblock h2 {
        font-size: 1.8em;
    }
}

@media only screen and (min-width: 1200px) {
    .bio-split {
        grid-template-columns: 30% 70%;
    }
    .bio-left-wrap {
        width: 30%;
    }
    .bio-textblock p {
        column-count: 2;
    }
    .bio-textblock {
        width: 90%;
    }
}