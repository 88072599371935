/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .home5-head {
        position: absolute;
        font-size: 3.4em;
        width: 100%;
        text-align: center;
        letter-spacing: -2px;
        z-index: 10;
        top: 40vw;
        font-weight: 800;
    }

    .home5-panel1 {
        position: relative;
        height: 100vw;
    }

    .home5-panel1 p {
        position: absolute;
        font-size: 1em;
        top: 84vw;
        text-align: right;
        width: 80vw;
        right: 2em;
        font-weight: 600;
    }

    .home5-panel1 img {
        position: absolute;
        width: 45vw;
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        top: 0;
        z-index: 0;
        opacity: 0.9;
        object-fit: cover;
        object-position: center;
    }
    .home5-panel2 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.6em;
        padding: 0.6em;
    }
    .home5-panel2-img {
        height: 400px;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
    .home5-panel2-text {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0.5em 0;

    }
    .home5-panel2-text button {
        font-size: 1em;
    }
}

@media only screen and (min-width: 600px) {
    .home5-head {
        top: 35vw;
        font-size: 4em;
    }
    .home5-panel1 img {
        width: 30vw;
    }
    .home5-panel1 {
        height: 80vw;
    }
    .home5-panel1 p {
        top: 65vw;
        font-size: 1.1em;
    }
    .home5-panel2 {
        width: 90%;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 800px) {
    .home5-panel1 img {
        width: 20vw
    }
    .home5-head {
        font-size: 3.6em;
        top: 25vw;
    }
    .home5-panel1 p {
        top: 40vw;
        width: 60vw;
    }
    .home5-panel1 {
        height: 60vw;
    }
    .home5-panel2 {
        grid-template-columns: 1fr 1fr;
        gap: 1em;
    }
}

@media only screen and (min-width: 1000px) {
    .home5-panel1 {
        height: 55vw;
    }
    .home5-panel1 img {
        width: 20vw;
    }
    .home5-head {
        font-size: 5em;
    }
}

@media only screen and (min-width: 1200px) {
    .home5-panel1 img {
        width: 15vw;
    }
    .home5-head {
        top: 17vw;
    }
    .home5-panel1 p {
        top: 30vw;
    }
    .home5-panel1 {
        height: 75vh;
    }
}